<template>
  <div class="wrap">
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
       style="position: absolute;left: 18px;top: 24px;"
        @click="onClickLeft"
         v-show="!backIcon"
      />
      <span style="font-size: 20px; font-weight: bold"
        >线上报表</span
      >
    </div>
    <div class="wrap_list">
      <div class="list" v-for="(item, i) in tableList" :key="i" @click="onClickInfo(item)">
        <div class="list_title">
          <i
            :class="'iconfont ' + item.iconName"
            style="margin-right: 5px; font-size: 16px"
          ></i
          >{{ item.reportTitle }}
        </div>
        <van-icon
          name="arrow"
          size="20"
          style="font-size: 20px; font-weight: bold; margin-right: 20px"
          
        />
      </div>
    </div>
    <div class="emptyBox" v-if="tableList.length == []">
        <img src="../../assets/images/empty.png" />
        <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { getList } from "@/api/online";
import { setStore, getStore } from "@/utils/localStorage.js";
export default {
  data() {
    return {
      tenantId: getStore({name:'tenantId'}),
      backIcon:localStorage.getItem("routerHistory"),
      tableList: [
        // { value: '1', label: '门诊数据', router: '/outpatient' },
        // { value: '2', label: '出院数据', router: '/hospital' },
        // { value: '3', label: '入院数据', router: '/inHospital' },
        // { value: '4', label: '手术数据', router: '/surgery' },
        // { value: '5', label: '手术室使用情况', router: '/operating' },
        // { value: '6', label: '昨日当天门诊量', router: '/numbers' },
        // { value: '6', label: '文本数据', router: '/textInfo' },
      ],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      getList(this.tenantId).then((res) => {
        if (res.data.code == "200") {
          this.tableList = res.data.data;
        }
      });
    },
    onClickLeft() {
      // this.$router.back()
      this.$router.push({ path: "/menu" });
    },
    onClickInfo(item) {
      this.$router.push({
        path: item.routeUrl,
        query: { id: item.id, title: item.reportTitle },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 20px;
  // background-color: #F6F6F6;
    // padding-top: 20px;
    // height: 100vh;

  .nav_bar {
    // text-align: center;
    // color: #323233;
    // color: #fff;
     text-align: center;
        color: #323233;
        // background-color: #1578F9;
      padding-bottom: 20px;
  }

  .wrap_list {
    // padding-left: 20px;
    font-size: 16px;
    width: 92%;
    margin: 15px auto 0;
    background: #fff;

    .list {
      display: flex;
      justify-content: space-between;
      height: 52px;
      line-height: 52px;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;

      .list_title {
        color: #333;
        margin-left: 10px;
      }
    }
  }
}

.van-icon-arrow {
  color: #999;
}

/deep/.van-badge--fixed {
  top: 25px !important;
  right: -20px !important;
}
.emptyBox{
  width:100%;
  height:100%;
  text-align: center;
  margin: 20% auto;
  img{
    width:50%;
    height:50%;
    margin:0 auto;
  }
}
</style>
